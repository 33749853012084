import { useEffect } from 'react';

import {
  Row,
  Col,
} from 'reactstrap';

import { useRouter } from 'next/router';

import Button from '@/components/button';
import Loading from '@/components/loading';
import WithState from '@/engine/decorators/with-state';
import WithUser from '@/engine/decorators/with-user';
import WithActions from '@/engine/decorators/with-actions';

const ActivateAccount = ({
  publish,
  message,
  loaded = false,
  user,
}) => {
  const router = useRouter();
  const { token, id } = router.query;

  if (user?.isLoggedIn && user?.emailVerified) {
    router.push('/');
    return null;
  }

  useEffect(() => {
    if (token && id) {
      publish({ event: 'load', token, id });
    }
  }, [token, id]);

  if (!loaded) {
    return <Loading />;
  }

  return (
    <div className="text-center">
      <h1>{message}</h1>
      <Row>
        <Col xs="12" md="6" lg="4" className="ml-auto mr-auto">
          <hr className="w-100" />
          <Button
            id="forgot.password.form.return"
            color="secondary"
            type="submit"
            size="sm"
            className="d-flex ml-auto mr-auto"
          >
            Return to Login
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default WithUser(WithActions(WithState(ActivateAccount)));
